<template>
  <div class="row">
    <div class="modal fade" id="modal-form-asignacion-tep-postulacion-nacional">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Postulación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Vehículo</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.vehiculo.placa"
                    :class="
                      $v.form.vehiculo.id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @change="buscarVehiculos()"
                    :disabled="bloquear"
                  />
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Conductor</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.conductor.numero_documento"
                    :class="
                      $v.form.conductor.id.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @change="buscarConductores()"
                    :disabled="bloquear"
                  />
                  {{ form.conductor.nombres }}
                  {{ form.conductor.apellidos }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.nacionalesPostulaciones.edit') &&
                  !$v.form.$invalid &&
                  form.conductor.id !== null &&
                  form.vehiculo.id !== null &&
                  !bloquear
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "PostulacionNacionalAsignacion",
  components: {},
  data() {
    return {
      bloquear: false,
      moment: moment,
      hoy: moment().format("YYYY-MM-DD"),
      data_viaje: {},
      form: {
        conductor: {
          numero_documento: null,
        },
        vehiculo: {
          placa: null,
        },
      },
    };
  },

  validations() {
    return {
      form: {
        conductor: {
          numero_documento: {
            required,
          },
          id: {
            required,
          },
        },
        vehiculo: {
          placa: {
            required,
          },
          id: {
            required,
          },
        },
      },
    };
  },

  methods: {
    async llenar_modal(item) {
      let fechaSolicitud = moment(item.fecha_ini).format("YYYY-MM-DD HH:mm:ss");
      let fechaSolicitud3 = moment(item.fecha_ini)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      let fechaActual = moment().format("YYYY-MM-DD HH:mm:ss");

      if (item.postulacion_nacional != null) {
        fechaSolicitud = fechaSolicitud3;
      }
      this.form = {
        conductor: {
          numero_documento: null,
        },
        vehiculo: {
          placa: null,
        },
      };

      // if (fechaSolicitud > fechaActual) {
      this.bloquear = false;
      this.data_viaje = item;
      if (item.postulacion_nacional != null) {
        this.form.conductor = item.postulacion_nacional.conductor;
        this.form.vehiculo = item.postulacion_nacional.vehiculo;
      }
      /*  } else {
        this.$swal({
          icon: "error",
          title: `Se ha excedido el tiempo para actualizar la postulación`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });

        this.bloquear = true;
      } */
    },

    async buscarConductores() {
      this.falgValidando = true;

      this.$parent.cargando = true;
      let dato = this.form.conductor.numero_documento;
      let res = await this.validarStatus(dato, "C");
      this.$parent.cargando = false;

      if (res && res.length != 0) {
        //activar validacion documentos
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.form.conductor = {};
          this.falgValidando = false;
          return false;
        }

        if (res.has_asignacion == 1) {
          this.form.conductor.numero_documento = null;
          this.form.conductor.nombres = null;
          this.form.conductor.apellidos = null;
          this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de otra postulación`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        if (!res.has_lineas) {
          this.form.conductor.numero_documento = null;
          this.form.conductor.nombres = null;
          this.form.conductor.apellidos = null;
          this.$swal({
            icon: "error",
            title: `El conductor no esta asignado a la linea de negocio de TEP`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        this.form.conductor = res[0];
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form.conductor = {};
      }
      this.falgValidando = false;
    },

    async buscarVehiculos() {
      this.falgValidando = true;
      this.$parent.cargando = true;

      let dato = this.form.vehiculo.placa;
      let res = await this.validarStatus(dato, "V");
      this.$parent.cargando = false;

      if (res && res.length != 0) {
        //activar validacion de documentos
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.form.vehiculo.placa = null;
          this.falgValidando = false;
          return false;
        }

        if (res.has_asignacion == 1) {
          this.form.vehiculo.placa = null;
          this.$swal({
            icon: "error",
            title: `El vehículo se encuentra postulado dentro de otra postulación o turno`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        if (!res.has_tipo_v) {
          this.form.vehiculo.placa = null;
          this.$swal({
            icon: "error",
            title: `El tipo de vehículo no coincide`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        /*  if (!res.gps) {
          this.form.vehiculo.placa = null;
          this.$swal({
            icon: "error",
            title: `El vehículo no reporta GPS a pegaso`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } */

        if (!res.has_lineas) {
          this.form.vehiculo.placa = null;
          this.$swal({
            icon: "error",
            title: `El vehículo no esta asignado a la línea de negocio de TEP`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        this.form.vehiculo = res[0];
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.falgValidando = false;
        this.form.vehiculo.placa = null;
        return false;
      }
      this.falgValidando = false;
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tep/PostulacionNacional/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tep/PostulacionNacional/listaVehiculos";
      }

      let params = {
        entidad,
        tipo_operacion: this.data_viaje.solicitudes.tipo_operacion,
        dato,
        fecha: moment(this.data_viaje.fecha_ini).format("YYYY-MM-DD"),
        tiempo: moment(this.data_viaje.fecha_ini).format("YYYY-MM-DD HH:mm:ss"),
        tipo_vehiculo: this.data_viaje.tipo_vehiculo_id,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentacion",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'>Para mas información comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    save() {
      const fechaActual = new Date();
      const fechaSolicitud = new Date(this.data_viaje.fecha_ini);
      // if (fechaSolicitud > fechaActual) {
      if (!this.$v.form.$invalid) {
        this.$swal({
          title:
            "Está seguro de postular el vehículo con placas: " +
            this.form.vehiculo.placa +
            " y conductor: " +
            this.form.conductor.nombres +
            " " +
            this.form.conductor.apellidos,
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Confirmar!",
        }).then((result) => {
          if (result.value) {
            this.$parent.cargando = true;
            axios({
              method: "POST",
              url: "/api/tep/PostulacionNacional/asignar",
              data: {
                form: this.form,
                viaje: this.data_viaje.id,
                empresa_id: this.data_viaje.transportadora_id,
                solicitud_id: this.data_viaje.solicitudes.id,
              },
            }).then((response) => {
              this.$parent.cargando = false;
              this.$swal({
                icon: "success",
                title: "Los datos se guardaron exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.$refs.closeModal.click();
              this.$parent.getIndex();
            });
          }
        });
      }
      /* } else {
        this.$swal({
          icon: "error",
          title: "Se ha excedido el tiempo para actualizar la postulación",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$refs.closeModal.click();
        this.$parent.getIndex();
        this.form = null;
      } */
    },
  },
};
</script>
